import { DataTableLocal } from "../data_table";
import { PlatformIcon } from "../../campaigns/utils";
import { useApiGet } from "../../../utils/api";
import { LoaderCircle } from "lucide-react";
import { Button } from "react-bootstrap";
import { DrawerBase, makeModal } from "../../../utils/make_modal";

export const useViewCreatorDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      object,
    }: {
      refetch: () => void;
      close: () => void;
      object: any;
    }) => {
      const { data, loading } = useApiGet(`/admin/insight_iq/creators/${object.id}`);

      if (loading) {
        return <LoaderCircle className="spin" size={32} />;
      }

      return (
        <DrawerBase title="Creator" close={close}>
          <div className="fs-12 white-space-break">
            {JSON.stringify(data.data, null, 2)}
          </div>
        </DrawerBase>
      );
    }
  );

export const Creators = () => {
  const { data, loading } = useApiGet("/admin/insight_iq/creators");
  const ViewCreatorDrawer = useViewCreatorDrawer();

  if (loading) {
    return <LoaderCircle className="spin" size={32} />;
  }

  return (
    <div>
      <DataTableLocal
        data={data.creators}
        schema={[
          {
            name: "user.name",
            header: "User",
            value: (p) => (
              <div>
                <div>{p.user.name}</div>
                <div className="fs-12 gray">{p.user.id}</div>
              </div>
            ),
          },
          {
            name: "name",
            header: "Creator",
            value: (p) => (
              <div>
                <div>{p.name}</div>
                <div className="fs-12 gray">{p.id}</div>
              </div>
            ),
          },
          {
            name: "insight_iq_connection.id",
            header: "InsightIQ Id",
            value: (p) =>
              p.insight_iq_connection?.id ? p.insight_iq_connection.api_id : "",
          },
          {
            name: "creator_platforms",
            header: "Creator Platforms",
            value: (p) => (
              <>
                {p.creator_platforms.map((cp) => (
                  <div className="d-flex align-items-center gap-2">
                    <PlatformIcon
                      object={cp}
                      className={cp.connected ? "green" : "light-gray"}
                    />
                  </div>
                ))}
              </>
            ),
          },
          {
            name: "view",
            header: "",
            value: (p) => (
              <Button variant="link" onClick={() => ViewCreatorDrawer.set(p)}>
                View
              </Button>
            ),
          },
        ]}
      />

      <ViewCreatorDrawer />
    </div>
  );
};
