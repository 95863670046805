export const DataModel = () => {
  return (
    <div>
      <h2 className="mb-3">DataModel</h2>

      <div>Creators</div>
      <div className="ml-3">
        <div className="fs-12 gray">has one</div>
        <div className="">
          InsightIqConnection
          <div className="ml-3">
            <div className="">insight_iq_id (insight iq user id)</div>
          </div>
        </div>
      </div>
      <div className="ml-3 mt-2">
        <div className="">
          <div className="fs-12 gray">has many</div>
          CreatorPlatforms
          <div className="ml-3">
            <div className="">platform</div>
            <div className="">insight_iq_account_id (insight iq account id)</div>
            <div>
              <div className="mt-3">InsightIq Relations (using account id)</div>
              <div className="ml-3">
                <div className="">Profile</div>
                <div className="">Audience</div>
                <div className="">Contents</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
