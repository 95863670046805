import { DataTableLocal } from "../data_table";
import { PlatformIcon } from "../../campaigns/utils";
import { useApiGet, usePageData } from "../../../utils/api";
import { LoaderCircle } from "lucide-react";
import { useDetailsDrawer } from "./details_drawer";
import { Button } from "react-bootstrap";

export const CreatorPlatforms = () => {
  const { data, loading } = useApiGet("/admin/insight_iq/creator_platforms");
  const DetailsDrawer = useDetailsDrawer();

  if (loading) {
    return <LoaderCircle className="spin" size={32} />;
  }

  return (
    <div>
      <DataTableLocal
        data={data.creator_platforms}
        schema={[
          { name: "uuid", header: "Creator Platform Uuid" },
          { name: "creator.user.name", header: "Creator" },
          {
            name: "handle",
            header: "Handle",
            value: (p) => <PlatformIcon object={p} externalLink />,
          },
          { name: "insight_iq_account_id", header: "InsightIQ Account Id" },
          {
            name: "view",
            header: "",
            value: (p) => (
              <Button variant="link" onClick={() => DetailsDrawer.set(p)}>
                View
              </Button>
            ),
          },
        ]}
      />

      <DetailsDrawer />
    </div>
  );
};
