import { DataTableLocal } from "../data_table";
import { LoaderCircle } from "lucide-react";
import { useApiGet } from "../../../utils/api";
import { useDetailsDrawer } from "./details_drawer";
import { Button } from "react-bootstrap";
import { PlatformIcon } from "../../campaigns/utils";

export const Profiles = () => {
  const { data, loading } = useApiGet("/admin/insight_iq/profiles");
  const DetailsDrawer = useDetailsDrawer();

  if (loading) {
    return <LoaderCircle className="spin" size={32} />;
  }

  return (
    <div>
      <DataTableLocal
        data={data.profiles}
        schema={[
          { name: "id" },
          { name: "user.name", header: "User" },
          { name: "full_name", header: "Profile Name" },
          {
            name: "account",
            header: "Handle",
            value: (p) => (
              <PlatformIcon
                platform={p.work_platform.name.toLowerCase()}
                handle={p.username}
                externalLink
              />
            ),
          },
          {
            name: "reputation",
            header: "Subscribers",
            value: (p) => p.reputation?.subscriber_count || "-",
          },
          {
            name: "reputation",
            header: "Videos",
            value: (p) => p.reputation?.content_count || "-",
          },
          {
            name: "view",
            header: "",
            value: (p) => (
              <Button variant="link" onClick={() => DetailsDrawer.set(p)}>
                View
              </Button>
            ),
          },
        ]}
      />

      <DetailsDrawer />
    </div>
  );
};
