import { Tabs, Tab } from "react-bootstrap";
import { CreatorPlatforms } from "./creator_platforms";
import { Accounts } from "./accounts";
import { Profiles } from "./profiles";
import { Users } from "./users";
import { Creators } from "./creators";
import { DataModel } from "./data_model";

const AdminInsightIq = () => {
  return (
    <div>
      <h1>InsightIQ</h1>

      <Tabs defaultActiveKey="creators" className="mb-3 mt-5">
        <Tab eventKey="creators" title="Creators" mountOnEnter>
          <Creators />
        </Tab>
        <Tab eventKey="creator-platforms" title="Creator Platforms" mountOnEnter>
          <CreatorPlatforms />
        </Tab>
        <Tab eventKey="accounts" title="Accounts" mountOnEnter>
          <Accounts />
        </Tab>
        <Tab eventKey="profiles" title="Profiles" mountOnEnter>
          <Profiles />
        </Tab>
        <Tab eventKey="users" title="Users" mountOnEnter>
          <Users />
        </Tab>
        <Tab eventKey="data-model" title="Data Model" mountOnEnter>
          <DataModel />
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminInsightIq;
