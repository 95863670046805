import { DrawerBase, makeModal } from "../../../utils/make_modal";

export const useDetailsDrawer = () =>
  makeModal(
    ({
      refetch,
      close,
      object,
    }: {
      refetch: () => void;
      close: () => void;
      object: any;
    }) => {
      return (
        <DrawerBase title="InsightIQ" close={close}>
          <div className="fs-12 white-space-break">{JSON.stringify(object, null, 2)}</div>
        </DrawerBase>
      );
    }
  );
