import { DataTableLocal } from "../data_table";
import { useApiGet } from "../../../utils/api";
import { LoaderCircle } from "lucide-react";
import { useDetailsDrawer } from "./details_drawer";
import { Button } from "react-bootstrap";

export const Users = () => {
  const { data, loading } = useApiGet("/admin/insight_iq/users");
  const DetailsDrawer = useDetailsDrawer();

  if (loading) {
    return <LoaderCircle className="spin" size={32} />;
  }

  return (
    <div>
      <DataTableLocal
        data={data.users}
        schema={[
          { name: "id" },
          { name: "name" },
          { name: "status" },
          {
            name: "view",
            header: "",
            value: (p) => (
              <Button variant="link" onClick={() => DetailsDrawer.set(p)}>
                View
              </Button>
            ),
          },
        ]}
      />

      <DetailsDrawer />
    </div>
  );
};
