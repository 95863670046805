import classNames from "classnames";
import { capitalize, get, intersection, sortBy } from "lodash";
import PaginationBar from "./pagination_bar";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { formatOptions } from "../../campaigns/fields";

const Sorter = ({ name, filters, setFilter, children }) => {
  const { sort, sortDir } = filters;
  const isSortColumn = sort === name;

  const onClick = () => {
    if (isSortColumn) {
      setFilter({ sortDir: sortDir === "asc" ? "desc" : "asc" });
    } else {
      setFilter({ sort: name, sortDir: "asc" });
    }
  };
  const iconDir = { asc: "up", desc: "down" }[sortDir];

  return (
    <span className="pointer white-space-pre" onClick={onClick}>
      {children}
      {isSortColumn && (
        <i className={`bi bi-caret-${iconDir}-fill ml-1 fs-12 sort-caret`} />
      )}
    </span>
  );
};

const SearchField = ({ name, filters, onChange, style }) => (
  <div className="input-group input-group-sm" style={style}>
    <span className="input-group-text">
      <i className="bi bi-search" />
    </span>
    <input
      type="text"
      onChange={(e) => onChange(e.target.value)}
      className="form-control w-200px"
      placeholder="Search"
      value={filters[name] || ""}
    />
    {filters.search && (
      <button
        type="button"
        className="btn bg-transparent"
        style={{ marginLeft: -30, zIndex: 5 }}
        onClick={() => onChange("")}
      >
        <i className="bi bi-x" />
      </button>
    )}
  </div>
);

const FilterMultiSelect = ({ name, placeholder, options, onChange, selected }) => {
  const [search, setSearch] = useState<string>(null);

  let filteredOptions = formatOptions(options).filter((o) =>
    search ? o.value.match(new RegExp(search, "i")) : o.value
  );

  if (options.length > 10) {
    filteredOptions = sortBy(filteredOptions, (o) => !selected.includes(o.value));
  }

  const selectedLabels = formatOptions(options)
    .filter((o) => selected.includes(o.value))
    .map((o) => o.label);

  return (
    <Dropdown className="" onToggle={(e) => setSearch("")}>
      <Dropdown.Toggle
        variant="outline-secondary btn-sm"
        id="dropdown-basic"
        className="flex align-items-center"
      >
        <div className="flex px-1 flex align-items-center">
          <div className="flex fs-14">
            {placeholder || capitalize(name)}:
            <div
              className="d-block bold ml-1"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selected.length === 0 && "All"}
              {selected.length === 1 && selectedLabels.join(", ")}
              {selected.length > 1 && `${selected.length} Selected`}
            </div>
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-200px">
        <div className="border-bottom ph-2 pb-1">
          <div className="input-group input-group-sm">
            <span className="input-group-text">
              <i className="bi bi-search" />
            </span>
            <input
              type="text"
              className="form-control"
              value={search || ""}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div
            className="fs-12 mt-1 pointer blue"
            onClick={() => {
              onChange([]);
            }}
          >
            Clear Selected
          </div>
        </div>
        <div className="filter-multi-select-options pt-1">
          {filteredOptions.map((o) => (
            <label className="input-group filter-multi-select-option" key={o.value}>
              <input
                type="checkbox"
                value={o.value}
                className="mr-1"
                checked={selected.includes(o.value)}
                onChange={(e) => {
                  const newValues = e.target.checked
                    ? [...selected, e.target.value]
                    : selected.filter((s) => s !== o.value);
                  onChange(newValues);
                }}
              />
              {o.label}
            </label>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DataTable = ({
  schema,
  data,
  filters,
  setFilters,
  reset,
  sortable,
  filterControls,
  meta,
  widths,
  isFetching,
}: {
  schema: {
    cellClass?: string;
    header?: string | JSX.Element;
    headerClass?: string;
    label?: string | JSX.Element;
    name?: string;
    sortable?: boolean;
    type?: string | number;
    value?: string | ((v) => string | JSX.Element);
  }[];
  data: any[];
  filters: { [k: string]: string | number };
  setFilters: any;
  reset: () => void;
  sortable?: boolean;
  filterControls: {
    type?: "search" | "select";
    options?: any[];
    placeholder?: string;
    name: string;
    value?: string | ((args: any) => boolean);
  }[];
  meta: {
    page: number;
    pages: number;
    count: number;
  };
  widths?: number[];
  isFetching?: boolean;
}) => {
  return (
    <DataTableRemote
      schema={schema}
      data={data}
      sortable={sortable}
      widths={widths}
      filterControls={filterControls}
      setFilters={setFilters}
      filters={filters}
      reset={reset}
      meta={meta}
      isFetching={isFetching}
    />
  );
};

const flattenObject = (obj, parentKey = "", result = {}) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
};
const inferSchema = (data) =>
  Object.entries(flattenObject(data[1])).map(([key, value]) => ({
    name: key,
    value: (r) => get(r, key),
  }));

{
  /* <DataTableLocal
  schema={schema}
  data={data}
  sortable={sortable}
  widths={widths}
  filterControls={filterControls}
/> */
}

const DataTableRemote = ({
  schema,
  data,
  sortable,
  widths,
  filterControls,
  setFilters,
  filters,
  reset,
  meta,
  isFetching,
}) => {
  schema = schema || inferSchema(data);

  const setFilter = (newFilters) => setFilters({ ...filters, ...newFilters });

  return (
    <>
      <FiltersBar
        filterControls={filterControls}
        filters={filters}
        setFilter={setFilter}
        reset={reset}
      />

      <CoreTable
        schema={schema}
        data={data}
        filters={filters}
        setFilter={setFilter}
        sortable={sortable}
        widths={widths}
      />

      <PaginationBar
        meta={meta}
        onChange={(page) => setFilters({ ...filters, page })}
        isFetching={isFetching}
      />
    </>
  );
};

export const DataTableLocal = ({
  schema,
  data: originalData,
  sortable,
  widths,
  filterControls,
  className,
}: {
  schema: any;
  data: any;
  sortable?: boolean;
  widths?: any;
  filterControls?: any;
  className?: string;
}) => {
  schema = schema || inferSchema(originalData);

  const [filters, setFilters] = useState<{
    sort?: string;
    page?: number;
    sortDir?: string;
    other?: string;
  }>({});

  const setFilter = (newFilters) => setFilters({ ...filters, ...newFilters });
  const reset = () => setFilters({});

  const { sort, sortDir, page, ...dataFilters } = filters;

  let data = originalData;

  Object.entries(dataFilters)
    .filter(([_, v]) => v.length)
    .forEach(([name, values]) => {
      data = data.filter((row) => {
        const filterControl = filterControls.find((c) => c.name === name);
        const raw = get(row, filterControl.value);
        console.log(row);

        if (Array.isArray(raw)) {
          return intersection(raw, values).length > 0;
        } else {
          return values.includes(raw);
        }
      });
    });

  return (
    <>
      {filterControls && (
        <FiltersBar
          filterControls={filterControls}
          filters={filters}
          setFilter={setFilter}
          reset={reset}
        />
      )}

      <CoreTable
        schema={schema}
        data={data}
        filters={filters}
        setFilter={setFilter}
        sortable={sortable}
        widths={widths}
        className={className}
      />

      <PaginationBar
        meta={{ page: 1, pages: 1, count: data.length }}
        onChange={(page) => setFilters({ ...filters, page })}
      />
    </>
  );
};

const FiltersBar = ({ filterControls, filters, setFilter, reset }) => {
  if (!filterControls?.length) {
    return null;
  }

  return (
    <div className="flex mt-4 space-between-md flex-wrap" style={{ gap: 10 }}>
      {filterControls.map((filter) => (
        <div key={filter.name}>
          {filter.type === "search" && (
            <SearchField
              name={filter.name}
              filters={filters}
              onChange={(value) => setFilter({ [filter.name]: value, page: 1 })}
              style={{ flex: "1 0 300px" }}
            />
          )}
          {filter.type === "select" ||
            (filter.options && (
              <FilterMultiSelect
                name={filter.name}
                placeholder={filter.placeholder}
                selected={filters[filter.name] || []}
                options={filter.options}
                onChange={(values) => setFilter({ [filter.name]: values, page: 1 })}
              />
            ))}
        </div>
      ))}
      <div className="flex align-items-center fs-12 pointer ml-2" onClick={() => reset()}>
        Reset
      </div>
    </div>
  );
};

const CoreTable = ({
  schema,
  data,
  filters,
  setFilter,
  sortable,
  widths,
  className,
}: {
  schema: any;
  data: any[];
  filters: any;
  setFilter: (newFilters: any) => void;
  sortable: boolean;
  widths: (number | null)[];
  className?: string;
}) => {
  return (
    <table className={classNames("table table-striped", className)}>
      <thead>
        <tr>
          {schema.map((field, i) => {
            let headerClass;
            if (field.type === "number") {
              headerClass = "text-right";
            }

            return (
              <th
                className={classNames(
                  field.headerClass,
                  headerClass,
                  widths ? `w-${widths[i]}px` : null
                )}
                key={field.name}
              >
                {(sortable && field.sortable !== false) || field.sortable ? (
                  <Sorter name={field.name} filters={filters} setFilter={setFilter}>
                    {field.header !== undefined ? field.header : capitalize(field.name)}
                  </Sorter>
                ) : field.header !== undefined ? (
                  field.header
                ) : (
                  capitalize(field.name)
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.uuid || row.id}>
            {schema.map((field, i) => {
              const cell = formatRow(row, field);

              return (
                <td className={classNames(cell.className)} key={i}>
                  {cell.value}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const formatRow = (row, field) => {
  let value;
  const className = [field.cellClass];
  let raw = get(row, field.value || field.name);
  const name = field.name;

  if (typeof field.value === "function") {
    value = field.value(row);
    raw = value;
  } else {
    value = raw;
  }
  if (field.type === "number") {
    value = value?.toLocaleString();
    className.push("text-right");
  }
  if (Array.isArray(value)) {
    value = value.join(", ");
  }
  return { name, value, raw, className };
};

export default DataTable;
